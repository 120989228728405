import React, { useCallback } from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import BackIcon from '../../images/back.inline.svg';

import './index.css';

interface IProps {
    directions: Array<{
        header: string,
        id: number
    }>,
    count: number,
    activeDirection: number | null,
    onClickDirection: React.MouseEventHandler
}

const DirectionsList = ({ directions, count, activeDirection, onClickDirection }: IProps) => {
    const cn = useClassnames();

    const backToPreviousPage = useCallback(() => {
        window.history.back();
    }, []);

    return (
        <div className={cn('directions__wrapper')}>
            <div className={cn('directions__back')}>
                <BackIcon className={cn('directions__back-icon')} onClick={backToPreviousPage} />
            </div>
            <div>
                <div className={cn('directions__header')}>
                    <p>Вакансии</p>
                </div>
                <ul className={cn('directions__list')}>
                    <li
                        className={
                            cn('directions__list-item', {
                                'directions__list-item_active': !activeDirection
                            })
                        }
                        onClick={onClickDirection}
                    >
                        Все
                    </li>
                    {directions.map(((direction, i) => (
                        <li
                            key={i}
                            className={
                                cn('directions__list-item', {
                                    'directions__list-item_active': activeDirection === direction.id
                                })
                            }
                            data-id={direction.id}
                            onClick={onClickDirection}
                        >
                            {direction.header}
                        </li>
                    )
                    ))}
                </ul>
                <span className={cn('directions__count')}>{count}</span>
            </div>
        </div>

    );
};

export default DirectionsList;
