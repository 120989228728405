import React, { ReactNode } from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import { THeaderPosition } from '../../types/strapi/common';

import './index.css';

interface IMainBannerHeaderWrapper {
    children: ReactNode,
    position?: THeaderPosition
}

const rootClassName = 'main-banner-header-wrapper';

const MainBannerHeaderWrapper = ({ children, position = 'top-left' }: IMainBannerHeaderWrapper) => {
    const cn = useClassnames();

    return (
        <div className={cn(rootClassName, `${rootClassName}_${position}`)}>
            {children}
        </div>
    );
};

export default MainBannerHeaderWrapper;
